@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("Quicksand-Light"),
    url("./font/Quicksand-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("Quicksand-Regular"),
    url("./font/Quicksand-Regular.ttf") format("truetype");
}
